<template>
  <div>
    <TTableSimple
      v-if="container_id"
      :items="data || []"
      :fields="fields"
      size="lg"
      columnFilter
      pagination
      :loading="references_fetching"
      :options="{ sorter: true }"
      class="table-custom"
    >
      <template #_="{ item }">
        <td>
          <TButton
            icon="cil-eye"
            tooltip="View"
            color="primary"
            variant="outline"
            size="sm"
            @click="viewReceipt(item)"
          />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          placeholder="Bill of lading code"
          :value.sync="lading_bill_id"
        />
      </template>
      <template #_-filter>
        <TButtonClear
          variant="outline"
          class="mb-1 ml-2"
          @click="clearTableFilter"
        />
      </template>
      <template #id="{ item }">
        <td>
          <TLink
            :content="item.id"
            :to="lodash.getReferenceLink('lading_bill', item.id)"
          />
        </td>
      </template>
      <template #customer="{ item }">
        <td>
          <TMessage :content="item.id" noTranslate />
        </td>
      </template>
      <template #freight_charge_debited="{ item }">
        <td>
          <TMessageMoney
            :amount="item.freight_charge_debited"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #freight_charge_paid="{ item }">
        <td>
          <TMessageMoney
            :amount="item.freight_charge_paid"
            :currency="item.currency_id"
          />
        </td>
      </template>
      <template #freight_charge_outstanding="{ item }">
        <td>
          <TMessageMoney
            :amount="item.freight_charge_outstanding"
            :currency="item.currency_id"
          />
        </td>
      </template>
    </TTableSimple>
    <ModalTransactions
      v-if="showDetailVocherModal"
      :show.sync="showDetailVocherModal"
      :voucher="voucher_selected"
      :default_filter="{
        'filter[receipts.receiptable_id]': voucher_selected.id,
        'filter[receipts.receiptable_type]': 'lading_bill',
      }"
    />
  </div>
</template>

<script>
import ModalTransactions from "../../components/ModalTransactions";
export default {
  components: { ModalTransactions },
  props: {
    container_id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      lading_bill_id: "",
      references_fetching: false,
      showDetailVocherModal: false,
      voucher_selected: "",
      fields: [
        {
          key: "_",
          label: "#",
          showCount: true,
          countLabel: "Count",
          _style: "width: 120px; min-width: 120px",
        },
        {
          key: "id",
          label: "Lading bill",
          _classes: "text-nowrap",
          _style: "width: 120px; min-width: 120px",
        },
        {
          key: "freight_charge_debited",
          label: "Debited",
          sorter: true,
          _style: "width: 220px; min-width: 220px",
        },
        {
          key: "freight_charge_paid",
          label: "Paid",
          sorter: true,
          _style: "width: 220px; min-width: 220px",
        },
        {
          key: "freight_charge_outstanding",
          label: "Outstanding",
          sorter: true,
          _style: "width: 220px; min-width: 220px",
        },
      ],
    };
  },
  computed: {
    container() {
      return this.$store.getters["warehouse.containers_object.detail"];
    },
    data() {
      return this.lodash.filter(
        this.$store.getters["warehouse.containers_object.detail"]
          ?.lading_bills || [],
        this.lodash.pickBy({
          id: +this.lading_bill_id,
        })
      );
    },
  },
  methods: {
    getTotal(key) {
      const sum = this.lodash.sumBy(this.data, key) || 0;
      return Math.round(sum * 100) / 100 || 0;
    },
    clearTableFilter() {
      this.lading_bill_id = "";
    },
    viewReceipt(item) {
      this.voucher_selected = item;
      this.showDetailVocherModal = true;
    },
  },
};
</script>

<style></style>
