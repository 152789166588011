<template>
  <CRow class="mb-5">
    <CCol lg="4" xl="3">
      <TAside class="card">
        <CListGroup>
          <CListGroupItem>
            <CRow>
              <CCol col="12">
                <SSelectInvoice
                  :value.sync="filter.id"
                  prependAll
                  label="Container"
                  class="mb-2"
                />
              </CCol>
              <CCol col="12" class="d-flex mt-2">
                <TButton
                  class="w-100"
                  content="Filter"
                  @click="onFilter"
                  :disabled="objects_fetching"
                />
                <TButtonClear
                  @click="clearFilter"
                  variant="outline"
                  class="ml-1"
                  size=""
                />
              </CCol>
            </CRow>
          </CListGroupItem>
          <CListGroupItem
            v-for="(container, index) in list"
            :key="`${index}`"
            :color="
              selected.container_id == container.id ? 'primary' : 'secondary'
            "
            tag="a"
            class="cursor-pointer"
            @click="selectContainer(container.id)"
          >
            <div class="d-flex">
              <SMessageShipmentMethod
                :id="container.shipment_method_id"
                iconOnly
              />
              <TMessage :content="container.name" bold noTranslate />
            </div>
          </CListGroupItem>
          <CListGroupItem v-if="objects_fetching">
            <TSpinner :loading="objects_fetching" />
          </CListGroupItem>
          <CListGroupItem v-if="!list.length && !objects_fetching">
            <TMessageNotFound />
          </CListGroupItem>
          <SButtonLoadmore
            v-if="!objects_fetching && list.length"
            store="warehouse.containers_object"
            class="mt-2 mb-3"
          />
        </CListGroup>
      </TAside>
    </CCol>
    <CCol lg="8" xl="9" v-if="selected.container_id && !lodash.isEmpty(detail)">
      <div class="car p-">
        <SMessageContainer
          v-if="!lodash.isEmpty(detail)"
          :container="detail"
          class="mb-2 h4"
        />
        <TTabs
          v-if="selected.container_id && !lodash.isEmpty(detail)"
          :tabs="overview_tabs"
          :activeTab="$route.query.overview_tab"
          @change-tab="changeTab($event, 'overview_tab')"
        >
          <template v-for="tab in overview_tabs" #[tab.key]>
            <CRow class="bg-white m-0 p-3" :key="tab.key">
              <CCol md="12">
                <TTableAsForm
                  :fields="overvewFields"
                  :data="tab.data"
                  :splitLeft="7"
                  title="Costs"
                >
                  <template #debited="{ data }">
                    <TMessageMoney
                      :amount="data.debited_input"
                      :currency="tab.key.toUpperCase()"
                    />
                  </template>
                  <template #paid="{ data }">
                    <TMessageMoney
                      :amount="data.paid_input"
                      :currency="tab.key.toUpperCase()"
                    />
                  </template>
                  <template #outstanding="{ data }">
                    <TMessageMoney
                      :amount="data.outstanding_input"
                      :currency="tab.key.toUpperCase()"
                    />
                  </template>
                  <template #freight_charge="{ data }">
                    <TMessageMoney
                      :amount="data.freight_charge"
                      :currency="tab.key.toUpperCase()"
                    />
                  </template>
                </TTableAsForm>
              </CCol>
              <CCol col="12">
                <TTableAsForm
                  :fields="[
                    {
                      key: 'profit',
                      label: 'Profit',
                    },
                  ]"
                  :data="tab.data"
                  :splitLeft="7"
                  class="mt-2 h4 text-primary m-0"
                >
                  <template #profit="{ data }">
                    <TMessageMoney
                      :amount="data.profit"
                      :currency="tab.key.toUpperCase()"
                      class="h4"
                    />
                  </template>
                </TTableAsForm>
              </CCol>
            </CRow>
          </template>
        </TTabs>
      </div>
      <TTabs
        v-if="selected.container_id && !lodash.isEmpty(detail)"
        :tabs="tabs"
        :activeTab="$route.query.tab"
        @change-tab="changeTab($event, 'tab')"
      >
        <template #input>
          <TableTransactions
            v-if="selected.container_id && !lodash.isEmpty(detail)"
            :key="transactionTBkey"
            store="accounting.transactions"
            :default_filter="{
              'filter[receipts.receiptable_id]': selected.container_id,
              'filter[receipts.receiptable_type]': 'container',
            }"
          />
        </template>
        <template #output>
          <DebtOutput
            v-if="selected.container_id"
            :container_id="selected.container_id"
          />
        </template>
      </TTabs>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters } from "vuex";
import DebtOutput from "./DebtOutput.vue";
import TableTransactions from "../../components/TableTransactions";

export default {
  components: { DebtOutput, TableTransactions },
  data() {
    return {
      overvewFields: [
        {
          key: "freight_charge",
          label: "Freight charge"
        },
        {
          key: "debited",
          label: "Debited",
        },
        {
          key: "paid",
          label: "Paid",
        },
        {
          key: "outstanding",
          label: "Outstanding",
        },
      ],
      tabs: [
        {
          key: "input",
          name: "Input",
          icon: "cisLibraryBooks",
        },
        {
          key: "output",
          name: "Output",
          icon: "cisLibraryBooks",
        },
      ],
      overview_tabs: [
        {
          key: "vnd",
          name: "VND",
          icon: "cilMoney",
          data: {},
          noTranslate: true,
        },
        {
          key: "jpy",
          name: "JPY",
          icon: "cilMoney",
          data: {},
          noTranslate: true,
        },
      ],
      filter: {
        object_id: "",
      },
      startDateKey: "startDateKey",
      endDateKey: "endDateKey",
      transactionTBkey: "",
      isOverviewJpy: true,
    };
  },
  watch: {
    queryRoute: {
      immediate: true,
      deep: true,
      handler(query) {
        if (!this.lodash.isEmpty(query)) {
          this.filter = {
            ...query,
          };
        }
      },
    },
    "queryRoute.container_id_selected": {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          if (newVal) {
            this.getContainerAcounting(newVal, "VND").then(({ data }) => {
              this.overview_tabs[0].data = data;
            });
            if (this.list.find((x) => x.id == newVal))
              this.selectContDetail(newVal);
          }
          this.transactionTBkey = this.lodash.resetKey("transactionTBkey");
        }
      },
    },
    overview_jpy: {
      immediate: true,
      handler(val) {
        if (val && this.selected?.container_id && this.isOverviewJpy)
          this.getContainerAcounting(this.selected?.container_id, "JPY").then(
            ({ data }) => {
              this.overview_tabs[1].data = data;
              this.isOverviewJpy = false;
            }
          );
      },
    },
    currencies: {
      immediate: true,
      handler(list) {
        if (list.length) {
          this.overview_tabs.forEach((x, i) => {
            this.overview_tabs[i].name = list.find((y) => y.id == x.name)?.name;
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      detail: "warehouse.containers_object.detail",
      list: "warehouse.containers_object.list",
      objects_fetching: "warehouse.containers_object.loading",
      currencies: "accounting.currencies.list",
    }),
    selected() {
      return {
        container_id: this.$route.query.container_id_selected,
      };
    },
    overview_jpy() {
      if (!this.$route.query?.overview_tab) return false;
      return this.$route.query.overview_tab == "0" ? false : true;
    },
    queryRoute() {
      return (
        this.lodash.omit(this.$route.query, [
          "transaction_id",
          "tab",
          "overview_tab",
        ]) || {}
      );
    },
  },
  methods: {
    appendRoute() {
      this.lodash.appendRouteQuery(this.$router, {
        query: {
          ...this.$route.query,
          id: this.filter?.id,
        },
      });
    },
    onFilter() {
      if (this.filter.currency_id) this.showTransation = true;
      this.appendRoute();
      this.fetchList(this.filter);
    },
    clearFilter() {
      this.lodash.appendRouteQuery(this.$router, {
        query: this.lodash.pick(this.$route.query, "container_id_selected"),
      });
      this.showTransation = false;
      this.radioKey = this.lodash.resetKey("radioKey");
      this.filter = {};
    },
    fetchList(filter) {
      this.loading = true;
      this.$store
        .dispatch("warehouse.containers_object.apply-query", {
          "filter_between[created_at]": `${filter.start_day || "infinity"},${
            filter.end_day || "infinity"
          }`,
          "filter[id]": filter.id,
          include: "ladingBills",
        })
        .catch((error) => {
          this.$store.dispatch("errors.push-http-error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeTab(tab, param) {
      this.$router.push({ query: { ...this.$route.query, [param]: tab } });
    },
    selectContainer(id) {
      if (id == this.queryRoute?.container_id_selected) return;
      this.isOverviewJpy = true;
      this.lodash.appendRouteQuery(this.$router, {
        query: {
          ...this.$route.query,
          overview_tab: 0,
          container_id_selected: id,
        },
      });
      this.selectContDetail(id);
    },
    selectContDetail(id) {
      this.$store.commit("warehouse.containers_object.select", id);
    },
    getContainerAcounting(cont_id, currency_id) {
      return this.$tomoni.warehouse.containers.getContainerAcounting(cont_id, {
        currency_id,
      });
    },
  },
  mounted() {
    this.lodash.appendRouteQuery(this.$router, {
      query: this.lodash.omit(this.$route.query, "container_id_selected"),
    });
  },
};
</script>
